import React, { useState } from 'react';
import EngFlag from '../../../images/flag-gb.svg';
import KorFlag from '../../../images/flag-ko.svg';
import ChevronDown from '../../../images/chevron-down-performid.svg';
import * as styles from './styles.module.scss';

const LanguageSelector = ({ className }) => {
  const [icon, setIcon] = useState(EngFlag);

  const options = [
    {
      language: 'English',
      flag: EngFlag,
    },
    {
      language: 'Korean',
      flag: KorFlag,
    },
  ];

  const handleSelectChange = (e) => {
    if (e.target.value === 'korean') {
      return setIcon(KorFlag);
    }

    return setIcon(EngFlag);
  };

  return (
    <div className={`${styles.selectContainer} ${className || undefined}`}>
      <img src={icon} alt="flag icon" className={styles.selectIcon} />
      <img src={ChevronDown} alt="down icon" className={styles.downIcon} />
      <select className={styles.select} onChange={(e) => handleSelectChange(e)}>
        {options.length > 0 &&
          options.map((option) => (
            <option key={option?.language} value={option?.language.toLowerCase()}>
              {option?.language}
            </option>
          ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
