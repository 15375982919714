import React from 'react';
import { Section, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const FeatureColumns = (props) => {
  const { data } = props;
  const { primary } = data;
  const features = [];
  [1, 2].forEach((count) => {
    features.push({
      icon: primary[`feature_column_${count}_icon`],
      content: primary[`feature_column_${count}_content`],
    });
  });

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="FeatureColumns">
      {features.length > 0 &&
        features.map((feature) => {
          const icon = feature?.icon;
          const content = feature?.content;

          return (
            <div className={styles.iconContentContainer} key={icon.url}>
              {icon && <Image image={icon} className={styles.icon} />}
              {content && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
            </div>
          );
        })}
    </Section>
  );
};

export default FeatureColumns;
