import React from 'react';
import { Form } from 'components';
import * as styles from './styles.module.scss';

const FormNewsletter = () => {
  const fields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'First Name',
      label: 'First name',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email Address',
      label: 'Email Address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a vald email',
    },
  ];

  return (
    <div className={styles.formNewsletter}>
      <h5 className={styles.formNewsletterTitle}>Subscribe to the newsletter</h5>
      <Form fields={fields} removeLabels submitButtonText="Subscribe" formId="CzWmkbWl" />
    </div>
  );
};

export default FormNewsletter;
