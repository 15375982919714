import React, { useState } from 'react';
import { Section, TestimonialCard, CarouselSlick } from 'components';
import * as styles from './styles.module.scss';

const Testimonials = (props) => {
  const { data } = props;
  const primary = data?.primary;
  const items = data?.items;
  const title = primary?.testimonials_title;
  const backgroundColour = primary?.background_colour;

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false,
          // dots: true,
        },
      },
    ],
  };

  return (
    <Section
      className={`${styles.section} ${backgroundColour === 'white' ? styles.whiteBg : styles.offWhiteBg}`}
      containerClassName={styles.container}
      sliceName="Testimonials"
    >
      {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={`${styles.title}`} />}
      <CarouselSlick settings={settings}>
        {(items || []).map((testimonial, index) => (
          <TestimonialCard data={testimonial} key={testimonial?.testimonial_about?.text} />
        ))}
      </CarouselSlick>
    </Section>
  );
};

export default Testimonials;
