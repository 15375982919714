import React from 'react';
import { Section, Image, Form, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Contact = (props) => {
  const { data } = props;
  const { primary } = data;
  const { contact_title: title, contact_subtitle: subtitle, contact_image: image } = primary;

  const fields = [
    {
      type: 'text',
      name: 'name',
      placeholder: 'Enter your full name',
      label: 'Name *',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Enter your email',
      label: 'Email *',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a vald email',
    },
    {
      type: 'text',
      name: 'subject',
      placeholder: 'Overview of your enquiry',
      label: 'Subject *',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter a subject',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: 'Let us know how we can help you',
      label: 'Message *',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter a message',
    },
  ];

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Contact" noContainer>
      <OnVisible className={styles.notYetVisible} visibleClassName={styles.visible}>
        <div className={`container ${styles.contentContainer}`}>
          <div>
            {title && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
            {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle.html }} className={styles.subtitle} />}
          </div>
          <Form fields={fields} className={styles.form} formId="SDExXuyD" />
        </div>
        {image && <Image image={image} className={styles.image} />}
      </OnVisible>
    </Section>
  );
};

export default Contact;
