import React, { useState, useEffect, useContext } from 'react';
import { use100vh } from 'react-div-100vh';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Link, Logo, Hamburger, LanguageSelector } from 'components';
import * as styles from './styles.module.scss';

const Header = ({ location }) => {
  const [showNavBurger, setShowNavBurger] = useState(false);
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true

  // LINKS DATA FROM SETTINGS
  const headerQuery = graphql`
    query {
      ...HeaderLinks
    }
  `;

  const { headerNavigationSettings } = useStaticQuery(headerQuery);
  const { data } = headerNavigationSettings;

  const headerLinks = [];
  [1, 2, 3, 4].forEach((count) => {
    headerLinks.push({
      headerNavLinkText: data[`header_navigation_link_${count}_text`],
      headerNavLinkSlug: data[`header_navigation_link_${count}_link`],
    });
  });

  /// H A M B U R G E R   L O G I C /////////////////////

  // toggle main nav links for mobile
  const toggleNav = (event) => {
    event.preventDefault();
    setShowNavBurger(!showNavBurger);
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K
  const handleEscKey = (event) => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNavBurger(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNavBurger && !bodyLocked) lockBody();
    if (!showNavBurger && bodyLocked) unlockBody();
  }, [showNavBurger]);

  // close nav if current page is selected from nav
  const handleClick = (event, link) => {
    event.preventDefault();
    if (location?.pathname === link) {
      setShowNavBurger(false);
    } else {
      navigate(link);
    }
  };

  // toggle visibility for mobile
  const hamburgerOpenClass = showNavBurger ? styles.hamburgerOpen : '';

  const height = use100vh();

  return (
    <header className={`${styles.header} ${hamburgerOpenClass || ''}`}>
      <div className={`container ${styles.container || ''}`}>
        {/* NAV */}
        <div className={styles.navigationContainer} style={showNavBurger ? { height } : {}}>
          <div className={styles.navLeft}>
            <div className={styles.logoContainer}>
              <Logo />
            </div>
          </div>
          <Navigation data={headerLinks} handleClick={handleClick} />
          {/* CTAS */}
          <div className={styles.navRight}>
            <LanguageSelector className={styles.languageSelector} />
            <Link to="/contact" className={`button secondary ${styles.contactUs}`}>
              Contact Us
            </Link>
          </div>
          <Hamburger onClick={toggleNav} active={showNavBurger} />
        </div>
      </div>
    </header>
  );
};


// links component
const Navigation = ({ data, handleClick }) => {
  const [activeSublinksIndex, setActiveSublinksIndex] = useState(false);
  // toggle sub links for mobile
  const showNavSublinks = (event, index) => {
    event.preventDefault();
    if (activeSublinksIndex === index) {
      setActiveSublinksIndex(false);
    }
    if (activeSublinksIndex !== index) {
      setActiveSublinksIndex(index);
    }
  };

  return (
    <nav className={styles.nav}>
      {data.map((section, index) => {
        const { headerNavLinkText: linkText, headerNavLinkSlug: linkUrl } = section;

        return (
          <div key={linkText?.text} className={styles.navItem}>
            <Link
              key={linkText?.text}
              className={styles.parentLink}
              to={`${linkUrl?.url}`}
              activeClassName={styles.parentLinkActive}
            >
              {linkText?.text}
            </Link>
          </div>
        );
      })}
    </nav>
  );
};

export default Header;
