import React from 'react';
import { Image, Section, Link, OnVisible } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './styles.module.scss';

const HomeTwoColsCtaImageGrid = (props) => {
  const homeTwoColsCtaImageGridSettingsQuery = graphql`
    query {
      ...HomeTwoColsCtaImageGridSettings
    }
  `;

  const { homeTwoColsCtaImageGridSettings } = useStaticQuery(homeTwoColsCtaImageGridSettingsQuery);
  const { data } = homeTwoColsCtaImageGridSettings;
  const { home_two_cols_cta_image_grid_group: twoColsCtaImageGridData } = data;

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      {twoColsCtaImageGridData.length > 0 &&
        twoColsCtaImageGridData.map((twoColsCtaImage, index) => {
          const image = twoColsCtaImage?.home_two_cols_grid_image;
          const title = twoColsCtaImage?.home_two_cols_cta_grid_title;
          const subtitle = twoColsCtaImage?.home_two_cols_cta_grid_subtitle;
          const ctaText = twoColsCtaImage?.home_two_cols_cta_grid_cta_link_text;
          const ctaSlug = twoColsCtaImage?.home_two_cols_cta_grid_cta_link_slug;
          const ctaColour = twoColsCtaImage?.home_two_cols_cta_grid_link_colour;

          return (
            <OnVisible className={styles.notYetVisible} visibleClassName={styles.visible} key={image?.url}>
              <div className={`${styles.twoColsContainer} ${index % 2 !== 0 ? styles.oddTwoColsContainer : undefined}`}>
                <div className={styles.col1Container}>
                  {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
                  {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
                  {ctaSlug?.url && (
                    <Link to={`${ctaSlug?.url}`} className={`button ${ctaColour.toLowerCase()}`}>
                      {ctaText?.text}
                    </Link>
                  )}
                </div>
                <div className={styles.col2Container}>
                  <Image image={image} />
                </div>
              </div>
            </OnVisible>
          );
        })}
    </Section>
  );
};

export default HomeTwoColsCtaImageGrid;
