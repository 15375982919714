module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "PerformID, Asia's leading Performance Marketing Agency", // Default Site Title used for SEO & PWA
  description:
    'PerformID has over 10 years experience of scaling and building both publishers and advertisers across Asia Pacific through affiliate and performance marketing channels. Contact us today to discuss accessing new customers in China, Korea, Hong Kong, Singapore, Taiwan, Japan, South East Asia and Australia / New Zealand.', // Default Site Decription used for SEO
  siteName: 'PerformID', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/static/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language

  // JSONLD / Manifest
  icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'PWA Name', // shortname for manifest. MUST be shorter than 12 characters
  author: 'PWA Author', // Author for schemaORGJSONLD
  themeColor: '#181c56', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#ffffff', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet
  googleTagManagerId: 'GTM-NSCMCTH',
  twitter: '', // Twitter Username
};
